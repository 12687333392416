import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import FullScreenSection from "../components/full-screen-section"
import Seo from "../components/seo"

import styled from 'styled-components'

const IndexPage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        bg1:

        file(relativePath: { eq: "pointcloud.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg2:
        file(relativePath: { eq: "obj.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg3:
        file(relativePath: { eq: "pointcloud3.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

      }
    `
  )

  // const data2 = useStaticQuery(
  //   graphql`
  //     query {
  //       desktop: file(relativePath: { eq: "obj.jpg" }) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_noBase64
  //           }
  //         }
  //       }
  //     }
  //   `
  // )  

  const bg1 = data.bg1.childImageSharp.fluid
  const bg2 = data.bg2.childImageSharp.fluid
  const bg3 = data.bg3.childImageSharp.fluid

  return (
    <Layout>
      <Seo title="Home" />

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

        	<div className={`row pt-3`}>

        		<div className={`col-md-4 col-lg-4 col-xl-4 col-xs-12 col-sm-12 ps-3 text-center text-end-sm `}>
              <Link to="/repositorio" className={`home-mouseover`}>
        			<p className={`text-black fs-0 p-0 m-0 lh-1`}><span className={`pointer-cursor`}>Repo<br />sitorio</span></p>
              </Link>
        		</div>
        		<div className={`col-md-4 col-lg-4 col-xl-4  col-xs-12 col-sm-12 text-center text-end-sm`}>
              <Link to="/ae" className={`home-mouseover`}>
              <p className={`text-black fs-7rem p-0 m-0 lh-1`}><span className={`pointer-cursor`}>A.E.</span><span className={`d-block pointer-cursor fs-6 p-0 m-0 lh-1`}><b>Actividades educativas</b></span></p>
              </Link>
        		</div>
        		<div className={`col-md-4 col-lg-4 col-xl-4  col-xs-12 col-sm-12 text-center text-end-sm `}>
              <Link to="/3d" className={`home-mouseover`}>
        			<p className={`text-black fs-4 p-0 m-0 lh-1`}><span className={`pointer-cursor`}><b>Experiencia</b></span><span className={`d-block pointer-cursor fs-7rem p-0 m-0 lh-1`}>3D</span></p>
              </Link>
        		</div>        		        		


        	</div>

            <div className={`row flex-1`}>
              <div className={`col align-self-end`}>
              	<h1 className={`text-white font-italic display-1`}><em>Curva de la Luz</em></h1>
              	<h3 className={`text-white font-italic`}>Proyecto de recuperación digital 3D “Paso Inferior Santa Lucía PISL” - Ortúzar, Vial y Bonati.</h3>
              </div>
              <div className={`col-2 align-self-end pb-2 d-none`}>
                <StaticImage
                  alt={`Curva de la Luz`}
                  src="../images/fondart.png"
                  width={600}
                  quality={95}
                  style={{ 
                  }}
                />
              </div>          
            </div>
            <div className={`row ribbon no-gutters`}>

            	<div className={`col-3 ribbon-blue`}></div>
            	<div className={`col-2 ribbon-red`}></div>
            	<div className={`col-4 ribbon-navy`}></div>
            	<div className={`col-3 ribbon-gray`}></div>

            </div>

        </BackgroundImage>

      </FullScreenSection>

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg2}
          backgroundColor={`#040e18`}
        >

        	{/*<div className={`row pt-3`}>

        		<div className={`col`}>
        			<p className={`text-white text-center`}>Repositorio</p>
        		</div>
        		<div className={`col`}>
        			<p className={`text-white text-center`}>Actividades Educativas</p>
        		</div>
        		<div className={`col`}>
        			<p className={`text-white text-center`}>Experiencia 3D</p>
        		</div>        		        		


        	</div>*/}

            <div className={`row flex-1`}>

              <div className={`col-lg-6 col-xl-6 d-flex flex-column`}>

                <div className={`d-flex flex-1 flex-column flex-items-middle justify-end `}>
             	    <div className={`d-flex flex-row`}><div className={`responsive-bg-fix-white`}><p className={`text-black text-left display-3 w-600 responsive-bg-fix-white`}>4349 m2</p><p className={`w-600`}>Superficie de área protegida</p></div></div>
                  <div className={`d-flex flex-row items-end`}><div className={`responsive-bg-fix-white`}><p className={`text-black text-middle display-3 w-600 mb-0 pb-0 responsive-bg-fix-white`}>290 mts</p><div className={`align-self-end`}><p className={`lh-min w-600`}>lineales</p></div></div></div>
                  <div className={`d-flex flex-row items-middle`}><div className={`responsive-bg-fix-white`}><p className={`text-black text-right display-3 w-600 mb-0 pb-0 responsive-bg-fix-white`}>2695 mts</p><div className={`align-self-end`}><p className={`lh-min w-600`}>de mosaico</p></div></div></div>
                </div>

                <div className={`d-flex pb-3 fix-logo-fondart-mobile`}>
                  <div className={`d-flex flex-row`} >
                    <div className={``}>
                      <StaticImage
                        alt={`Curva de la Luz`}
                        src="../images/fondart_solo.png"
                        width={300}
                        quality={95}
                        style={{ 
                        }}
                      />
                    </div>
                    <div className={`d-flex flex-column items-end`}>  
                      <p className={`fw-bold align-self-end pb-0 mb-0 ps-1 `}>Proyecto financiado por el Fondo del Patrimonio Cultural 2020 Servicio Nacional de Patrimonio</p>
                    </div>
                  </div>
                </div>

              </div>




              <div className={`col-xl-3 col-lg-3 col-xs-12 col-sm-12 col-md-12 d-flex`}>
                <div className={`flex-column w-100 d-flex items-end pb-3`}>
                  <div className={`d-flex flex-row text-center align-self-end space-between w-100`}>
                    <a target="_blank" href="https://instagram.com/curvadelaluz"><i className={`fab fa-instagram display-5`}></i></a>
                    <a target="_blank" href="https://www.facebook.com/curvadelaluz/"><i className={`fab fa-facebook-square display-5`}></i></a>
                    <a target="_blank" href="https://www.linkedin.com/in/curva-de-la-luz-69a125227/"><i className={`fab fa-linkedin display-5`}></i></a>
                    <a target="_blank" href="https://twitter.com/curvadelaluz"><i className={`fab fa-twitter-square display-5`}></i></a>
                    <a target="_blank" href="https://www.youtube.com/channel/UCS9cDb0TFcAWzXqpmwTZzgw"><i className={`fab fa-youtube display-5`}></i></a>
                    <a target="_blank" href="https://sketchfab.com/curvadelaluz"><i className={`fas fa-cube display-5`}></i></a>
                  </div>
                </div>
              </div>
{/*              <div className={`col-2 align-self-end pb-2 d-none`}>
                <StaticImage
                  alt={`Curva de la Luz`}
                  src="../images/fondart.png"
                  width={600}
                  quality={95}
                  style={{ 
                  }}
                />
              </div> */}         
            </div>
            <div className={`row ribbon no-gutters`}>

            	<div className={`col-3 ribbon-blue`}></div>
            	<div className={`col-2 ribbon-red`}></div>
            	<div className={`col-4 ribbon-navy`}></div>
            	<div className={`col-3 ribbon-gray`}></div>

            </div>

        </BackgroundImage>

      </FullScreenSection>

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg3}
          backgroundColor={`#040e18`}
        >

          {/*<div className={`row pt-3`}>

            <div className={`col`}>
              <p className={`text-white text-center`}>Repositorio</p>
            </div>
            <div className={`col`}>
              <p className={`text-white text-center`}>Actividades Educativas</p>
            </div>
            <div className={`col`}>
              <p className={`text-white text-center`}>Experiencia 3D</p>
            </div>                        


          </div>*/}

            <div className={`row flex-1`}>
              <div className={`col d-flex `}>
                <div className={`d-flex flex-1 flex-column flex-items-middle`}>
                  <h3 className={`text-black text-center display-6 w-600`}><span className={`bg-white-71`}>EQUIPO DE TRABAJO</span></h3>
                </div>
              </div>
            </div>

            <div className={`row flex-1`}>
              <div className={`col-lg-4 col-xl-4 offset-lg-1 offset-xl-1 col-xs-12 col-sm-12 col-md-12`}>
                
                    <p className={`text-white lh-min`}><span className={`bg-black-71`}>Dirección y producción:</span></p>
                    <p className={`text-white lh-min`}><span className={`bg-black-71`}>Marcelo Fica</span></p>
                    <p className={`text-white lh-min`}><span className={`bg-black-71`}>Equipo de investigación:</span></p>
                    <p className={`text-white lh-min`}><span className={`bg-black-71`}>David Maulén</span></p>                    
                    <p className={`text-white lh-min`}><span className={`bg-black-71`}>Vania Montgomery</span></p>                    
                    <p className={`text-white lh-min`}><span className={`bg-black-71`}>Desarrollo website:</span></p>                    
                    <p className={`text-white lh-min`}><span className={`bg-black-71`}>Diego Sepúlveda</span></p>

                
              </div>

              <div className={`col-lg-4 col-xl-4 offset-lg-1 offset-xl-1 col-xs-12 col-sm-12 col-md-12 responsive-bg-fix-black`}>
                <p className={`text-white text-end lh-min`}><span className={`bg-black-71`}>Scanner y Fotogrametría:</span></p>
                <p className={`text-white text-end lh-min`}><span className={`bg-black-71`}>Diego Ramírez</span></p>
                <p className={`text-white text-end lh-min`}><span className={`bg-black-71`}>Retopología y color:</span></p>
                <p className={`text-white text-end lh-min`}><span className={`bg-black-71`}>Benjamin Downey</span></p>                    
                <p className={`text-white text-end lh-min`}><span className={`bg-black-71`}>Animación 3d:</span></p>                    
                <p className={`text-white text-end lh-min`}><span className={`bg-black-71`}>Benjamin Downey - Kurt Malonnek</span></p>                    
                <p className={`text-white text-end lh-min`}><span className={`bg-black-71`}>Difusión:</span></p>
                <p className={`text-white text-end lh-min`}><span className={`bg-black-71`}>Marco Martínez</span></p>
              </div>

            </div>

            <div className={`row flex-1`}>
              <div className={`col d-flex col-xs-12 col-sm-12 col-md-12`}>
                <div className={`d-flex flex-1 flex-column flex-items-middle`}>
                  <p className={`text-white text-center mb-0 pb-0 w-600`}><i>Agradecimientos: </i><br/></p>
                  <p className={`text-white text-center mt-0 pt-0 w-100`}><i>a Gastón Vega, Angélica Quintana, Eduardo Bonati, Pelagia Rodriguez, Yura Labarca, Diego Ramirez, Juan Guzmán e Iván Vial Montero</i></p>
                </div>
              </div>
            </div>            




            <div className={`row ribbon no-gutters`}>

              <div className={`col-3 ribbon-blue`}></div>
              <div className={`col-2 ribbon-red`}></div>
              <div className={`col-4 ribbon-navy`}></div>
              <div className={`col-3 ribbon-gray`}></div>

            </div>

        </BackgroundImage>

      </FullScreenSection>        

    </Layout>
  )

}

const StyledBackgroundSection = styled(IndexPage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default IndexPage
